import { Box, Grid, Stack, useMediaQuery, Theme } from "@mui/material";
import { useContext, useEffect } from "react";
import BackgroundSelect from "./BackgroundSelect";
import CentralContainer from "./CentralContainer";
import { ConfigContext, configType } from "./Configuration";
import Logo from "./Logo";
import MusicButton from "./MusicButton";
import ProgressBar from "./ProgressBar";
import SessionSelect from "./SessionSelect";
import RefreshButton from "./RefreshButton";
import { useForm, useFormState } from "react-final-form";

type LoginScreenProps = {
  showProgressBar: boolean;
  error: boolean;
  demoComplete: boolean;
};

const LoginScreen = ({ showProgressBar, error, demoComplete }: LoginScreenProps) => {
  const { values: formValues } = useFormState();
  const form = useForm();
  const config: configType = useContext(ConfigContext) as configType;
  const scaleUpperLimit = 5.0;
  const scaleLowerLimit = 0.5;

  // Use media query to check if the screen is small (mobile)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    // @ts-ignore
    const initialValue = document.body.style.zoom;

    // Change zoom level on mount
    const zoom =
      config.scale !== "" &&
      Number(config.scale) >= scaleLowerLimit &&
      Number(config.scale) <= scaleUpperLimit
        ? (Number(config.scale) * 100).toString() + "%"
        : "100%";
    if (config.scale === "") {
      form.change("scale", "1.0");
    }
    // @ts-ignore
    document.body.style.zoom = zoom;
    const middle = (document.body.scrollHeight / 2) * (Number(config.scale) - 1);
    window.scrollTo(0, middle);
    form.change("hideBackgroundSelect", config.hideBackgroundSelect);
    form.change("hideSessionSelect", config.hideSessionSelect);

    return () => {
      // Restore default value
      // @ts-ignore
      document.body.style.zoom = initialValue;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          padding: isMobile ? "1rem" : "0", // Add padding for mobile
        }}
      >
        <Stack
          sx={{
            width: isMobile ? "100%" : 765, // Full width on mobile
            height: isMobile ? "auto" : 503, // Auto height on mobile
            backgroundImage: `url(${config.background})`,
            display: "flex",
            justifyContent: "space-between",
            backgroundSize: "cover", // Ensure the background covers the area
            backgroundPosition: "center", // Center the background
            padding: isMobile ? "1rem" : "0", // Add padding for mobile
            boxSizing: "border-box", // Ensure padding doesn't affect box size
          }}
        >
          <Stack
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid
              container
              justifyContent={isMobile ? "center" : "center"}
              alignItems={isMobile ? "center" : "flex-start"}
              sx={{ flexGrow: isMobile ? 1 : 0 }} // Center the logo vertically on mobile
            >
              <Grid item xs={12} sm={2} />
              <Grid item xs={12} sm={8} display="flex" justifyContent="center">
                <Logo />
              </Grid>
              <Grid item xs={12} sm={2}>
                {!showProgressBar && !demoComplete && (
                  <BackgroundSelect visibility={!formValues.hideBackgroundSelect ? "visible" : "hidden"} />
                )}
              </Grid>
            </Grid>
            {!showProgressBar && !demoComplete ? (
              <CentralContainer error={error} />
            ) : !demoComplete ? (
              <ProgressBar />
            ) : (
              <RefreshButton />
            )}
          </Stack>
          {!showProgressBar && !demoComplete && (
            <Grid container>
              <Grid item xs={6}>
                <Box sx={{ m: isMobile ? "0.2rem" : "0.4rem" }}>
                  <SessionSelect visibility={!formValues.hideSessionSelect ? "visible" : "hidden"} />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    m: isMobile ? "0.2rem" : "0.4rem",
                  }}
                >
                  <MusicButton />
                </Box>
              </Grid>
            </Grid>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default LoginScreen;
